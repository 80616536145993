import classNames from "classnames";
import React, { FC, DOMAttributes, ReactNode } from "react";

export const Wrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="mb-20">{children}</div>
);

export const ItemWrapper: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="mb-5">{children}</div>
);

interface Props extends DOMAttributes<HTMLElement> {
  isOpen: boolean;
  children?: ReactNode;
}

export const ItemTitle: FC<Props> = ({ children, isOpen, ...props }) => {
  const className = classNames(
    "bg-gray-100 p-5 m-0 rounded font-normal text-xl text-black cursor-pointer flex justify-between hover:bg-gray-200 items-center",
    {
      "rounded-b-none border-b": isOpen,
    }
  );

  return (
    <h4 {...props} className={className}>
      {children}
    </h4>
  );
};

export const ItemContent: FC<Props> = ({ children, isOpen }) => (
  <h4 className={`bg-gray-50 p-5 w-full ${isOpen ? "block" : "hidden"}`}>
    {children}
  </h4>
);
