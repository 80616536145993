import React from "react";
import { FC } from "react";
import { SeoContent } from "@graphql/types";
import { Title } from "@components/ui/Title";

const SeoContentBlock: FC<SeoContent> = ({ title, content }) => {
  return (
    // css file define the styles 'seo.css'
    <div className="seo-content">
      <Title as="h3" size="md" divider>
        {title}
      </Title>
      <div
        className="whitespace-pre-line break-words"
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      />
    </div>
  );
};

export default SeoContentBlock;
