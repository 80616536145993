import React, { FC } from "react";
import Link from "next/link";
import { searchURL } from "@helpers/searchURL";
import Category, { CategoryTypes } from "@components/common/Category";
import { getAbsoluteUrl } from "@helpers/getAbsoluteUrl";
import { twMerge } from "tailwind-merge";

interface Props {
  filters: {
    country?: string;
    city?: string;
  };
  className?: string;
  hasTours: boolean;
  hasOnlineTours: boolean;
  hasGuides: boolean;
  hasTransportation: boolean;
}

export const CategoriesLinks: FC<Props> = ({
  filters,
  className = "",
  ...props
}) => {
  return (
    <div className={twMerge(`grid grid-cols-4 gap-2 bg-white`, className)}>
      {/* Guides */}
      {props.hasGuides && (
        <Link
          href={getAbsoluteUrl(searchURL({ ...filters, t: "guides" }))}
          passHref
        >
          <Category
            category={CategoryTypes.guides}
            selected={false}
            actived={props.hasGuides}
          />
        </Link>
      )}

      {/* Private tours */}

      {props.hasTours && (
        <Link
          href={getAbsoluteUrl(searchURL({ ...filters, t: "tours" }))}
          passHref
        >
          <Category
            category={CategoryTypes.tours}
            selected={false}
            actived={props.hasTours}
          />
        </Link>
      )}

      {/* Car tours */}
      {props.hasTransportation && (
        <Link
          href={getAbsoluteUrl(searchURL({ ...filters, t: "transportation" }))}
          passHref
        >
          <Category
            category={CategoryTypes.transportation}
            selected={false}
            actived={props.hasTransportation}
          />
        </Link>
      )}

      {/* Online tours */}
      {props.hasOnlineTours && (
        <Link
          href={getAbsoluteUrl(searchURL({ ...filters, t: "online-tours" }))}
          passHref
        >
          <Category
            category={CategoryTypes["online-tours"]}
            selected={false}
            actived={props.hasOnlineTours}
          />
        </Link>
      )}
    </div>
  );
};
