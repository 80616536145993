import React, { FC } from "react";
import MicroFormat from "..";

export interface FaqItem {
  answer: string;
  question: string;
}

interface Props {
  items: FaqItem[];
  image?: string | string[];
}

const FaqMicroFormat: FC<Props> = ({ items, image }) => {
  const itemListElement: Array<unknown> = [];

  items.map((item) => {
    itemListElement.push({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    });
  });

  const data = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: itemListElement,
    image,
  };

  return <MicroFormat data={data} />;
};

export default FaqMicroFormat;
