import React, { FC, ReactNode, useState } from "react";
import { Wrapper, ItemWrapper, ItemTitle, ItemContent } from "./styles";
import Icon from "@components/ui/Icon";

interface FaqItemProps {
  title: string;
  answer: string;
  className?: string;
  expanded?: boolean;
  highlightedTitle?: string;
  highlightedAnswer?: string;
}

const Item: FC<FaqItemProps> = ({
  title,
  answer,
  className = "",
  expanded,
  highlightedTitle,
  highlightedAnswer,
}) => {
  const [isOpen, setOpen] = useState(expanded || false);

  React.useEffect(() => {
    if (expanded !== undefined) {
      setOpen(expanded);
    }
  }, [expanded]);

  return (
    <ItemWrapper>
      <ItemTitle
        isOpen={isOpen}
        onClick={() => {
          setOpen(!isOpen);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: highlightedTitle || title }} />
        <button className="dropdown-arrow md:w-8 md:h-8 w-7 h-7 flex justify-center items-center">
          <Icon icon={isOpen ? "arrowUp" : "arrowDown"} />
        </button>
      </ItemTitle>
      <ItemContent isOpen={isOpen}>
        <div
          dangerouslySetInnerHTML={{ __html: highlightedAnswer || answer }}
          className={`m-0 font-normal leading-normal max-w-full content-with-links text-gray-700 text-base md:text-lg ${className}`}
        />
      </ItemContent>
    </ItemWrapper>
  );
};

interface FaqComponents {
  Item: typeof Item;
}

const Faq: FaqComponents & FC<{ children: ReactNode }> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

Faq.Item = Item; // Attach the Item component to Faq

export default Faq;
