import { Link } from "@components/common/LinksSection";
import { CountryFooterFragment } from "./getCountryFooterData";
import { CityFooterFragment } from "./getCityFooterData";

interface Options {
  hideDestinations?: boolean;
}

export const getLocationLinks = (
  country: CountryFooterFragment | undefined,
  city: CityFooterFragment | undefined,
  options: Options = {
    hideDestinations: false,
  }
): Link[] => {
  const location = country || city;

  if (!location) {
    return [];
  }

  const links: Link[] = [
    {
      title: `${location.name}`,
      url: location.url,
    },
  ];

  if (location.hasSuppliers) {
    links.push({
      title: `${location.name} Tour Guides`,
      url: `${location.url}/guides`,
    });
  }

  if (location.hasPrivateTours) {
    links.push({
      title: `${location.name} Private Tours`,
      url: `${location.url}/tours`,
    });
  }

  if (location.hasTransportationTours) {
    links.push({
      title: `${location.name} Private Car Tours`,
      url: `${location.url}/cars`,
    });
  }

  if (location.hasOnlineTours) {
    links.push({
      title: `${location.name} Virtual Tours`,
      url: `${location.url}/online-experiences`,
    });
  }

  if (options.hideDestinations === false) {
    if (country) {
      links.push({
        title: `${country.name} Destinations`,
        url: `${country.url}/destinations`,
      });
    }

    if (city?.country) {
      links.push({
        title: `${city?.country.name} Destinations`,
        url: `${city?.country.url}/destinations`,
      });
    }
  }

  return links;
};
