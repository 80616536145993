import React, { FC } from "react";
import { FaqContent } from "@graphql/types";
import { Title } from "@components/ui/Title";
import Faq from "..";

const FaqContentBlock: FC<{
  title: string;
  items: FaqContent[];
}> = ({ title, items }) => {
  return (
    <div className="mt-20 faq-content-with-links">
      <Faq>
        <Title size="md" as="h3" divider>
          {title}
        </Title>

        {items.map(({ question, answer }, index) => {
          return (
            <Faq.Item
              key={index}
              title={question}
              answer={answer}
              className="content-with-links"
            />
          );
        })}
      </Faq>
    </div>
  );
};

export default FaqContentBlock;
