import { Container } from "@components/ui/Container";
import { Spinner } from "@components/ui/Spinner";
import React from "react";

const LoadingPage = () => {
  return (
    <Container className="flex justify-center py-52">
      <Spinner />
    </Container>
  );
};

export default LoadingPage;
